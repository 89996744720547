<template>
  <div class="col-md-12">
    <div
      class="modal fade modal_cust show"
      v-if="modalId == 'editFollow'"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle1"
      style="display: block"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal_ac_head"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalCenterTitle">
              Edit Follow Up
            </h5>
            <a class="close" @click="$store.dispatch('modalClose', 'editFollow')">
              <span aria-hidden="true">×</span>
            </a>
          </div>
          <form
            @submit.prevent="submit"
            method="post"
            class="bg-theam-secondary"
            enctype="multipart/form-data"
          >
            <div class="modal-body row">
              <div class="col-md-6 pd-r-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group mb-3">
                      <label class="group-label">Basic Information</label>
                      <div class="group-attribute-container">
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">Follow Date *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="date"
                              id="income_id"
                              class="form-control"
                              placeholder="YYYY/MM/DD"
                              v-model="followData.follow_date"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div class="group" id="description">
                      <label class="group-label">Follow Report *</label>
                      <div class="group-attribute-container">
                        <div class="row">
                          <div class="col-md-12">
                            <textarea
                              class="form-control"
                              type="text"
                              name="description"
                              id="description"
                              style="height: 2.85rem"
                              v-model="followData.report"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 pd-l-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group">
                      <label class="group-label">Follow Inforamtion</label>
                      <div class="group-attribute-container">
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Next Follow *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="date"
                              name="paid_amount"
                              class="form-control"
                              placeholder=""
                              v-model="followData.next_follow_date"
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">To Do *</div>
                          <div class="col-md-7 input-group ml-0 pl-0">
                            <select
                              class="form-control"
                              v-model="followData.todo_id"
                            >
                              <option
                                v-for="(todo, index) in followUpEditElements.leadTodo"
                                :key="index"
                                :value="todo.id"
                              >
                                {{ todo.title }}
                              </option>
                            </select>
                            <!-- <div class="input-group-prepend">
                              <button class="btn-primary input-group-add-btn">
                                <i class="fas fa-plus"></i>
                              </button>
                            </div> -->
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Status *</div>
                          <div class="col-md-7 input-group ml-0 pl-0">
                            <select
                              class="form-control"
                              v-model="followData.status"
                            >
                              <option
                                v-for="(leadstatus, index) in followUpEditElements.leadStatus"
                                :key="index"
                                :value="leadstatus.id"
                              >
                                {{ leadstatus.title }}
                              </option>
                            </select>
                            <!-- <div class="input-group-prepend">
                              <button class="btn-primary input-group-add-btn">
                                <i class="fas fa-plus"></i>
                              </button>
                            </div> -->
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">Priority *</div>
                          <div class="col-md-7 input-group ml-0 pl-0">
                            <select
                              class="form-control"
                              v-model="followData.priority"
                            >
                              <option
                                v-for="(leadpriority,
                                index) in followUpEditElements.leadPriority"
                                :key="index"
                                :value="leadpriority.id"
                              >
                                {{ leadpriority.title }}
                              </option>
                            </select>
                            <!-- <div class="input-group-prepend">
                              <button class="btn-primary input-group-add-btn">
                                <i class="fas fa-plus"></i>
                              </button>
                            </div> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-right mt-3">
                <button type="submit" class="btn btn-sm btn-primary" :disabled="disableSubmitButton">
                  {{ disableSubmitButton ? 'Submitting....' : 'Submit'}}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <LeadProperty />
    <LeadSource />
    <LeadStatus />
    <LeadTodo />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import LeadProperty from "./Setting/leadPriority";
import LeadSource from "./Setting/leadSource";
import LeadStatus from "./Setting/leadStatus";
import LeadTodo from "./Setting/leadTodo";
import Services from "./Services/Services";
export default {
  data() {
    return {
      followData: {
        id: "",
        lead: this.$route.params.id,
        follow_date: "",
        report: "",
        next_follow_date: "",
        todo_id: "",
        status: "",
        priority: "",
      },
      disableSubmitButton:false,
    };
  },
  components: {
    LeadProperty,
    LeadSource,
    LeadStatus,
    LeadTodo,
  },
  computed: {
    ...mapGetters([
      "processing",
      "eventMessage",
      "dataLists",
      "dataLists1",
      "dataLists6",
      "dataLists3",
      "dataId",
      "modalId",
      "modalMode",
      "today",
    ]),
    ...mapGetters("leads",["followUpDatas","followUpEditElements"])
  },
  mounted() {
      
  },
  methods: {
    submit() {
      if (this.modalMode == "editFollow") {
        this.disableSubmitButton = true;
        Services.updateLeadFollow(this.followData,this.followData.id).then(res=>{
              //this.$store.commit("setDataLists6",res.data.data);
              this.$store.dispatch('modalClose');
              let success = res.data.success_message;
              this.setNotification(success);
              this.disableSubmitButton = false;
              this.$emit("follow-edit");
        }).catch(err=>{
          this.disableSubmitButton = false;
          if(err.response.status == 422){
                let error = Object.values(err.response.data.errors).flat();
                this.setNotification(error);
             }else{
                let error = err.response.data.error_message;
                this.setNotification(error);
             }
        });
        // this.$store.commit(
        //   "setApiUrl",
        //   `api/lead-follow/${this.followData.id}`
        // );
        // this.$store.commit("updateData", this.followData);
      }
    },
    setNotification(msg){
        this.$store.dispatch("enableMessage");
        this.$store.commit("setEventMessage",msg);
    },
    clearData() {
      this.followData.id = "";
      this.followData.lead = "";
      this.followData.follow_date = "";
      this.followData.report = "";
      this.followData.next_follow_date = "";
      this.followData.todo_id = "";
      this.followData.status = "";
      this.followData.priority = "";
      this.disableSubmitButton=false;
    },
    getElements(){
       Services.getFollowUpEditElements().then(res=>{
          this.$store.commit("leads/setFollowUpEditElements",res.data.data);
          let id = this.dataId;
        let details = this.followUpDatas.find(function (data) {
          return data.id == id;
        });
        this.followData = {...details};
        }).catch(err=>{
          console.log(err);
        })
    }
  },
  watch: {
    modalMode(value) {
      if(value == "editFollow"){
        this.clearData();
       this.getElements();
       
     }
    },
    eventMessage(value) {
      if (value.indexOf("success") >= 0) {
        this.clearData();
      }
    },
  },
};
</script>