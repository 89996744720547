<template>
  <div class="col-md-12">
    <div
      class="modal fade modal_cust show"
      v-if="modalId == 'newFollow'"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle1"
      style="display: block"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal_ac_head" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalCenterTitle">
              Add New Follow Up
            </h5>
            <a class="close" @click="$store.dispatch('modalClose', 'newlead')">
              <span aria-hidden="true">×</span>
            </a>
          </div>
          <form
            @submit.prevent="submit"
            method="post"
            class="bg-theam-secondary"
            enctype="multipart/form-data"
          >
            <div class="modal-body row">
              <div class="col-md-6 pd-r-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group mb-3">
                      <label class="group-label">Basic Information</label>
                      <div class="group-attribute-container">
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">Follow Date *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="date"
                              id="income_id"
                              class="form-control"
                              placeholder="YYYY/MM/DD"
                              v-model="followData.follow_date"
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div class="group" id="description">
                      <label class="group-label">Follow Report *</label>
                      <div class="group-attribute-container">
                        <div class="row">
                          <div class="col-md-12">
                            <textarea
                              class="form-control"
                              type="text"
                              name="description"
                              id="description"
                              style="height: 2.85rem"
                              v-model="followData.report"
                              required
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 pd-l-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group">
                      <label class="group-label">Follow Information</label>
                      <div class="group-attribute-container">
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Next Follow *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="date"
                              name="paid_amount"
                              class="form-control"
                              placeholder=""
                              required
                              v-model="followData.next_follow_date"
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">To Do *</div>
                          <div class="col-md-7 input-group ml-0 pl-0">
                            <select
                              class="form-control"
                              v-model="followData.todo_id"
                              required
                            >
                            <option value="" disabled>Select To do</option>
                              <option
                                v-for="(todo, index) in followUpElements.leadTodo"
                                :key="index"
                                :value="todo.id"
                              >
                                {{ todo.title }}
                              </option>
                            </select>
                            <!-- <div class="input-group-prepend">
                              <button class="btn-primary input-group-add-btn">
                                <i class="fas fa-plus"></i>
                              </button>
                            </div> -->
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Status *</div>
                          <div class="col-md-7 input-group ml-0 pl-0">
                            <select
                              class="form-control"
                              required
                              v-model="followData.status"
                            >
                            <option value="" disabled>Select Status</option>
                              <option
                                v-for="(leadstatus, index) in followUpElements.leadStatus"
                                :key="index"
                                :value="leadstatus.id"
                              >
                                {{ leadstatus.title }}
                              </option>
                            </select>
                            <!-- <div class="input-group-prepend">
                              <button class="btn-primary input-group-add-btn">
                                <i class="fas fa-plus"></i>
                              </button>
                            </div> -->
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">Priority *</div>
                          <div class="col-md-7 input-group ml-0 pl-0">
                            <select
                              class="form-control"
                              v-model="followData.priority"
                              required
                            >
                            <option value="" disabled>Select Priority</option>
                              <option
                                v-for="(leadpriority,
                                index) in followUpElements.leadPriority"
                                :key="index"
                                :value="leadpriority.id"
                              >
                                {{ leadpriority.title }}
                              </option>
                            </select>
                            <!-- <div class="input-group-prepend">
                              <button class="btn-primary input-group-add-btn">
                                <i class="fas fa-plus"></i>
                              </button>
                            </div> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-right mt-3">
                <button type="submit" class="btn btn-sm btn-primary" :disabled="disableSubmitButton">
                 {{ disableSubmitButton ? 'Submitting....' : 'Submit'}}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <LeadProperty />
    <LeadSource />
    <LeadStatus />
    <LeadTodo />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import LeadProperty from "./Setting/leadPriority";
import LeadSource from "./Setting/leadSource";
import LeadStatus from "./Setting/leadStatus";
import LeadTodo from "./Setting/leadTodo";
import Services from "./Services/Services";
export default {
  data() {
    return {
      followData: {
        id: "",
        lead: this.$route.params.id,
        follow_date: new Date().toJSON().slice(0,10).replace(/-/g,'-'),
        report: "",
        next_follow_date: "",
        todo_id: "",
        status: "",
        priority: "",
      },
      disableSubmitButton:false,
    };
  },
  components: {
    LeadProperty,
    LeadSource,
    LeadStatus,
    LeadTodo,
  },
  computed: {
    ...mapGetters([
      "processing",
      "eventMessage",
      "dataLists",
      "dataLists1",
      "dataLists3",
      "dataLists6",
      "dataId",
      "modalId",
      "modalMode",
      "today",
    ]),
    ...mapGetters("leads",['followUpElements'])
  },
  mounted() {
    
  },
  methods: {
    submit() {
      if (this.modalMode == "create") {
        this.disableSubmitButton = true;
        Services.createLeadFollow(this.followData).then(res=>{
           this.$store.dispatch('modalClose');
              let success = res.data.success_message;
              this.setNotification(success);
              this.disableSubmitButton=false;
              this.$emit("follow-create");
        }).catch(err=>{
          this.disableSubmitButton = false;
          if(err.response.status == 422){
                let error = Object.values(err.response.data.errors).flat();
                this.setNotification(error);
             }else{
                let error = err.response.data.error_message;
                this.setNotification(error);
             }
        });
      }
    },
    setNotification(msg){
        this.$store.dispatch("enableMessage");
        this.$store.commit("setEventMessage",msg);
    },
    setDate() {
      this.followData.follow_date = this.today;
    },
    clearData() {
      this.followData.id = "";
      this.followData.lead = this.$route.params.id;
      this.followData.follow_date = "";
      this.followData.report = "";
      this.followData.next_follow_date = "";
      this.followData.todo_id = "";
      this.followData.status = "";
      this.followData.priority = "";
    },
  },
  watch: {
    modalMode(value) {
      if (value == "create") {
        this.clearData();
        Services.getFollowUpElements().then(res=>{
          this.$store.commit("leads/setFollowUpElements",res.data.data);
        }).catch(err=>{
          console.log(err);
        })
        this.setDate();
      }
    },
  },
};
</script>