import { store } from "../../../../store/index";
import Api from "../../../../store/Services/Api";

/** 
 * javascript comment 
 * @Author: Ravi Sigdel 
 * @Date: 2021-03-24 10:57:37 
 * @Desc: Estimate Service 
 */
class EstimateService {
    getDataForEstimateCreation() {
        const url = 'api/estimate/create'
        const headers = {
            'Accept': 'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url, headers);
    }

    getPurchaseItemsByBranch(id) {
        const url = `api/sales/items/branch/${id}`
        const headers = {
            'Accept': 'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url, headers);
    }

    getDataByBarcode(barcode, branch) {
        const url = `api/sales/barcode/${barcode}/${branch}`
        const headers = {
            'Accept': 'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url, headers);
    }

    getItemsBySearchKeyword(params) {
        const url = `api/sales/items/search`
        const headers = {
            'Accept': 'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url, headers, params);
    }

    getLeadsBySearchKeyword(keyword, userType) {
        const url = `api/estimate/lead/search/${userType}/${keyword}`
        const headers = {
            'Accept': 'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url, headers);
    }

    getUserByUserType(usertype) {
        const url = `api/estimate/users-type/${usertype}`
        const headers = {
            'Accept': 'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url, headers);
    }

    storeEstimateData(data) {
        const url = `api/estimate/store`
        const headers = {
            'Accept': 'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.post(url, data, headers);
    }

    getSlipPredefinedData() {
        const url = `api/sales/slip/create`
        const headers = {
            'Accept': 'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url, headers);

    }

    getFilteredEstimates(queryParams) {
        const url = `api/get-estimates?page-index=${queryParams.pageIndex}`
        const params = {
            'branch': queryParams.branch,
            'sales-type': queryParams.sales_type,
            'today': queryParams.today,
            'month': queryParams.month,
            'year': queryParams.year,
            'from': queryParams.from_date,
            'to': queryParams.to_date,
            'searched': queryParams.searched,
            'page-reload': queryParams.page_reload,
            'offset': queryParams.offset
        }
        const headers = {
            'Accept': 'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url, headers, params);

    }

    cancelEstimate(id, remarks) {
        const url = `api/estimate/cancel/${id}`
        const headers = {
            'Accept': 'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.patch(url, remarks, headers);
    }

    getEstimateDataForEdit(id) {
        const url = `api/estimate/edit/${id}`
        const headers = {
            'Accept': 'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url, headers);
    }

    updateEstimateItem(id, data) {
        const url = `api/estimate/update/${id}`
        const headers = {
            'Accept': 'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.patch(url, data, headers);
    }

}

export default new EstimateService();