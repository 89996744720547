<template>
  <div class="col-md-12">
    <div
      class="modal fade modal_cust show"
      v-if="modalId == 'estimateSlip'"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle1"
      style="display: block"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal_ac_head"
        role="document"
      >
        <div class="modal-content bg-white border0">
          <div class="cancel-watermark" v-if="details.canceled_by">
            <div class="water-mark-content">Cancelled</div>
          </div>
          <div class="modal-header no-print">
            <div class="row">
              <div class="col-md-6">
                <h5 class="modal-title" id="exampleModalCenterTitle">
                  Estimate Slip - {{ details.estimate_code }}
                </h5>
              </div>
              <div class="col-md-6 d-flex justify-content-end">
                <div
                  class="bill-head-icon mr-3"
                  title="Print Slip"
                  @click="print()"
                >
                  <i class="fas fa-print"></i>
                </div>
                <!-- <div class="bill-head-icon mr-3" title="Download Slip">
                  <i class="fas fa-download"></i>
                </div>-->
                <div
                  class="bill-head-icon mr-1"
                  @click="$store.dispatch('modalClose', 'estimateSlip')"
                  title="Close Slip"
                >
                  <i class="fas fa-times"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="p-2 bill-container">
            <div class="text-center" v-if="slipSettings.organization">
              <p class="font-xxl mb-0">{{ slipSettings.organization.name }}</p>
              <p class="mb-0">
                {{ details.branch.address }}
                <br />
                Tel No: {{ details.branch.phone }} / {{ details.branch.email }}
                <br />
                PAN: {{ slipSettings.organization.pan_vat }}
              </p>
              <hr />
            </div>
            <!-- col -->
            <div class="row">
              <div class="col-sm-6 col-lg-8 mg-t-40 mg-sm-t-0">
                <label class="content-label mb-1">Estimate To</label>
                <!--    <h6 class="tx-15 mg-b-10">Juan Dela Cruz</h6> -->
                <p class="mg-b-0">NAME :{{ details.estimateable.name }}</p>
                <p class="mg-b-0">
                  ADDRESS :
                  <span v-if="details.estimateable.address">{{
                    details.estimateable.address
                  }}</span>
                </p>
                <!-- <p class="mg-b-0">PAN : {{details.estimateable.pan_vat_no}}</p> -->
              </div>
              <!-- col -->
              <div class="col-sm-6 col-lg-4 mg-t-15">
                <p class="mg-b-0 text-right">
                  Estimate ID : {{ details.estimate_code }}
                </p>
                <p class="mg-b-0 text-right">DATE : {{ details.date }}</p>
                <p class="mg-b-0 text-right" v-if="details.validity">
                  Validity : {{ details.validity }}
                </p>
              </div>
              <!-- col -->
            </div>
            <!-- row -->
            <div class="mt-2">
              <table class="table table3">
                <thead class="bg-none">
                  <tr class="bg-none">
                    <th scope class="wd-5p">S.N.</th>
                    <th scope class="wd-50p">Particular</th>
                    <th scope class="wd-15p">Qty</th>
                    <th scope class="wd-10p">Rate</th>
                    <th scope class="wd-10p">Discount</th>
                    <th scope class="wd-10p tx-right">Total</th>
                  </tr>
                </thead>
                <tbody class="hover-none">
                  <tr v-for="(item, index) in details.items" :key="index">
                    <th scope="row">{{ ++index }}</th>
                    <td>
                      {{ item.item.name }} <br>
                      <div class="font-xs">{{item.description}}</div>
                    </td>
                    <td>{{ item.quantity }} {{ item.item.unit_type.title }}</td>
                    <td>{{ item.rate }}</td>
                    <td>{{ parseDigitForSlip(item.discount_amount) }}</td>
                    <td class="tx-right">
                      {{ parseDigitForSlip(item.total) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row justify-content-between mt-2 font-s font-w-400">
              <div
                class="col-sm-7 col-lg-7 order-2 order-sm-0 mg-t-40 mg-sm-t-0"
              >
                <!-- Amount-in-word -->
                <div class="footer_detail mt-0 mb-2 p-2">
                  <label class="content-label mb-1">Amount In Word</label>
                  <p class="mg-b-0" v-if="details.estimate_total > 0">
                    {{ toWord(details.estimate_total) }} Only.
                  </p>
                </div>
              </div>
              <!-- col -->
              <div
                class="
                  col-sm-4 col-lg-4
                  order-1 order-sm-0
                  color-secondary
                  mb-0
                  mt-0
                "
              >
                <ul class="list-unstyled lh-6 mb-0">
                  <li class="d-flex justify-content-between">
                    <span>Sub-Total</span>
                    <span>{{ parseDigitForSlip(details.grand_total) }}</span>
                  </li>
                  <li
                    class="d-flex justify-content-between"
                    v-for="itemExtra in vatAbleExtraFields"
                    :key="itemExtra.title"
                  >
                    <span>{{
                      itemExtra.slug
                        | makeTitle(
                          itemExtra.slug,
                          slipSettings.sales_extra_fields
                        )
                    }}</span>
                    <span>{{ parseDigitForSlip(itemExtra.amount) }}</span>
                  </li>
                  <li class="d-flex justify-content-between">
                    <span>VAT able Amount</span>
                    <span>{{
                      parseDigitForSlip(details.vat_able_amount)
                    }}</span>
                  </li>
                  <li class="d-flex justify-content-between">
                    <span>VAT (13%)</span>
                    <span>{{ parseDigitForSlip(details.vat_amount) }}</span>
                  </li>
                  <li
                    class="d-flex justify-content-between"
                    v-for="(itemExtra, index) in nonVatAbleExtraFields"
                    :key="index"
                  >
                    <span>{{
                      itemExtra.title
                        | makeTitle(
                          itemExtra.slug,
                          slipSettings.sales_extra_fields
                        )
                    }}</span>
                    <span>{{ parseDigitForSlip(itemExtra.amount) }}</span>
                  </li>
                  <li class="d-flex justify-content-between">
                    <span>Total</span>
                    <span>{{ parseDigitForSlip(details.estimate_total) }}</span>
                  </li>
                </ul>
              </div>
              <!-- col -->
            </div>
            <hr />
            <!-- Footer Container -->
            <div class="print-mb-3">
              <div class="row">
                <div class="col-sm-9">
                  <div class="footer_note" v-if="details.note">
                    <label class="content-label mb-1 no-print-sm">Note</label>
                    <p class="mb-0 text-center text-sm-left font-print-xxs">
                      {{ details.note }}
                    </p>
                  </div>
                </div>
                <div class="col-sm-3">
                  <p
                    class="mb-0 mt-p-2 text-right font-print-xxs"
                    v-if="slipSettings.sales_setting"
                  >
                    <span>User: {{ details.user_detail.name }}</span>
                  </p>
                </div>
              </div>
              <div v-if="details.canceled_by">
                <hr />
                <div class="footer_note">
                  <label class="content-label mb-1">Cancelation Details</label>
                  <p class="mg-b-0 font-print-xxs">
                    Cancellation Date : {{ details.date }}
                  </p>
                  <p class="mg-b-0 font-print-xxs">
                    Cancellation User :
                    <span v-if="!details.canceled_user">{{
                      loggedInUserName
                    }}</span>
                    <span v-else>{{ details.canceled_user.name }}</span>
                  </p>
                  <p
                    class="mb-2 text-sm-left font-print-xxs"
                    v-if="details.canceled_note"
                  >
                    Remark: {{ details.canceled_note }}
                  </p>
                </div>
                <hr class="print-display-only-block" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      details: [],
      loggedInUserName: "",
    };
  },
  computed: {
    ...mapGetters(["dataLists", "dataId", "modalId", "modalMode"]),
    ...mapGetters("estimate", ["dataSets", "slipSettings"]),
    vatAbleExtraFields() {
      return this.details.extra_fields.filter((el) => {
        return el.vat == 1;
      });
    },
    nonVatAbleExtraFields() {
      return this.details.extra_fields.filter((el) => {
        return el.vat == 0;
      });
    },
  },
  filters: {
    makeTitle(value, slug, slipSettings) {
      var words = [];
      slipSettings.filter((ss) => {
        if (ss.slug == slug) {
          words.push(ss.title);
        }
      });

      return words.join(" ");
    },
  },
  mounted() {
    let user = JSON.parse(localStorage.getItem("authData"));
    this.loggedInUserName = user.user.name;
  },
  methods: {
    print() {
      window.print();
    },
    closeSlipModal() {
      this.$store.dispatch("modalClose");
    },
  },
  watch: {
    dataId(value) {
      this.details = this.dataSets.find(function (data) {
        return data.id == value;
      });
    },
  },
};
</script>