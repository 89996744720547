<template>
  <div class="col-md-12">
    <div
      class="modal fade modal_cust show"
      v-if="modalId == 'newLeadContact'"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle1"
      style="display: block"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal_ac_head single-column-popup"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalCenterTitle">
              Add Contact Person
            </h5>
            <a
              class="close"
              title="Close"
              @click="$store.dispatch('modalClose')"
            >
              <span aria-hidden="true">×</span>
            </a>
          </div>
          <form
            @submit.prevent="submit"
            method="post"
            class="bg-theam-secondary"
            enctype="multipart/form-data"
          >
            <div class="modal-body row">
              <div class="col-md-12 pd-r-7-5">
                    <div class="group">
                      <label class="group-label">Basic Information</label>
                      <div class="group-attribute-container">
                        
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Name *</div>
                          <div class="col-md-7 input-group ml-0 pl-0">
                            <input
                              v-model="formData.name"
                              required
                              class="form-control"
                              placeholder="Person Name.."
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Designation</div>
                          <div class="col-md-7 input-group ml-0 pl-0">
                            <input
                                    v-model="formData.designation"
                              class="form-control"
                              placeholder="Designation.."
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Email </div>
                          <div class="col-md-7 input-group ml-0 pl-0">
                            <input
                              type="email"
                              v-model="formData.email"
                              class="form-control"
                              placeholder="abc@domain.com"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">Phone *</div>
                          <div class="col-md-7 input-group ml-0 pl-0">
                            <input
                              v-model="formData.phone_no"
                              required
                              class="form-control"
                              placeholder="+977xxxxxxxx"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
              </div>
              <div class="col-md-12 text-right mt-3">
                <button type="submit" class="btn btn-sm btn-primary" :disabled="disableSubmitButton">
                  {{(disableSubmitButton)?'Submitting...':'Submit'}}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
import Services from './Services/Services';
  export default {
    data(){
      return{
        formData:{
          catagory: '',
          name:'',
          company: this.$route.params.id,
          phone_no: '',
          address: '',
          designation: '',
          email: '',
          note: '',
        },
        disableSubmitButton:false,
      }
    },
    computed: {
      ...mapGetters([
        "processing",
        "eventMessage",
        "dataLists",
        "dataLists1",
        "dataLists2",
        "dataLists3",
        "dataId",
        "modalId",
        "modalMode",
        "modalClose",
      ]),
      ...mapGetters("leads",[
        "contact_persons"
      ]),
    },
    mounted() {
          
    },
    methods: {
      submit() {
        if (this.modalMode == "create") {
          this.disableSubmitButton = true;
          Services.createContactPersons(this.formData).then((res)=>{
              this.contact_persons.push(this.formData);
              this.$store.dispatch('modalClose');
              this.setNotification(res.data.success_message);
          }).catch(err=>{
              if(err.response.status == 422){
                let error = Object.values(err.response.data.errors).flat();
                this.setNotification(error);
             }else{
                let error = err.response.data.error_message;
                this.setNotification(error);
             }
          }).finally(()=>{
              this.disableSubmitButton = false;
          });
        }
      },
      setNotification(msg){
        this.$store.dispatch("enableMessage");
        this.$store.commit("setEventMessage",msg);
    },
      clearData(){
          this.formData.catagory= '';
          this.formData.name='';
          this.formData.company= this.$route.params.id;
          this.formData.phone_no= '';
          this.formData.address= '';
          this.formData.designation= '';
          this.formData.email='';
          this.formData.note= '';
      }
    },
    watch: {
      modalId(value){
        if (value == "newLeadContact") {
        this.clearData();
          Services.getAccountTypeForContact().then(res=>{
            this.formData.catagory = res.data.data.account_type;
          }).catch(err=>{
                console.log(err.response.data.error_message);
          });
        }
      },
    },
  }
</script>