<template>
  <div class v-if="lead">
    <div class="content-body content-body-profile">
      <div class="profile-sidebar">
        <div class="content-header pd-t-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="/lead">Lead</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Lead Detail</li>
              </ol>
            </nav>
          </div>
        </div>
        <!-- content-header -->
        <div class="profile-sidebar-header">
          <div class="avatar">
            <img
              :src="lead.img ? lead.img : '/assets/img/user_icon.png'"
              class="rounded-circle"
              alt
            />
          </div>
          <h5>{{ lead.name }}</h5>
          
          <!-- <div class="d-flex">
          <label for="">Status</label>

            <div class="tx-primary pd-l-5 tx-normal">
              <span :class="` text-${lead.lead_status.color}` "><b>{{ lead.lead_status.title }}</b></span> 
            </div>
          </div> -->
          <div class="d-flex align-self-stretch mt-1">
            <a href class="btn btn-sm btn-primary btn-uppercase flex-fill">Email</a>
            <a
              href="javascript:;"
              v-if="checkSinglePermission('edit_lead')"
              class="btn btn-white btn-sm btn-uppercase flex-fill mg-l-5"
              @click="edit('editProfile', $route.params.id)"
            >Edit</a>
          </div>
        </div>
        <!-- profile-sidebar-header -->
        <div class="card-progress">
          <label class="content-label mg-b-0">Priority</label>
          <div class="progress">
            <div
              v-if="progress_color"
              :class="`progress-bar bg-${progress_color}`"
              role="progressbar"
              aria-valuenow="100"
              aria-valuemin="0"
              aria-valuemax="100"
              :style="{width: progrees_percent + '%'}"
            ></div>
          </div>
          <label class="content-label mg-b-0" v-if="lead.lead_priority">
            {{
            lead.lead_priority.title
            }}
          </label>
        </div>
        <div class="profile-sidebar-body">
          <label class="content-label">Company Contact</label>
          <ul class="list-unstyled profile-info-list mg-b-0">
            <li v-if="lead.address">
              <i class="fas fa-map-marker-alt"></i>
              <span class="tx-color-03 pl-2">{{ lead.address }}</span>
            </li>
            <li v-if="lead.phone_number">
              <i class="fas fa-phone"></i>
              <a href class="pl-2">{{ lead.phone_number }}</a>
            </li>
            <li v-if="lead.email">
              <i class="fas fa-envelope-open"></i>
              <a href="javascript:;" class="pl-2">{{ lead.email }}</a>
            </li>
            <li v-if="lead.website">
              <i class="fab fa-internet-explorer"></i>
              <a href="javascript:;" class="pl-2">{{ lead.website }}</a>
            </li>
          </ul>
          <hr class="mg-y-10" />

          <label class="content-label">Other Detail</label>
          <ul class="list-unstyled profile-info-list">
            <li>
              <span class="tx-color-03">Status: &nbsp;</span><span :class="`tx-${lead.lead_status.color}` ">&nbsp;{{lead.lead_status.title }}</span> 
            </li>
            <li>
              <span class="tx-color-03" v-if="lead.branch">Branch: {{ lead.branch.name }}</span>
            </li>
            <li v-if="lead.generate_date">
              <span class="tx-color-03">Lead Created: {{ lead.generate_date }}</span>
            </li>
            <li v-if="lead.source">
              <span class="tx-color-03">Source: {{ lead.lead_source.title }}</span>
            </li>
            <li>
              <span
                class="tx-color-03"
                v-if="lead.user_details"
              >Added By: {{lead.user_details.name}}</span>
            </li>
            <li v-if="lead.assigned_to">
              <span class="tx-color-03">Assigned To: {{ lead.assignee.name }}</span>
            </li>
          </ul>
        </div>
        <!-- profile-sidebar-body -->
      </div>
      <!-- profile-sidebar -->
      <div class="profile-body">
        <div class="profile-body-header">
          <div class="nav-wrapper">
            <ul class="nav nav-line" id="profileTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="overview-tab"
                  data-toggle="tab"
                  href="#overview"
                  role="tab"
                  aria-controls="overview"
                  aria-selected="true"
                >Overview</a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="projects-tab"
                  data-toggle="tab"
                  @click="getFollowupData()"
                  href="#bills"
                  role="tab"
                  aria-controls="projects"
                  aria-selected="false"
                >Follow Up</a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="people-tab"
                  @click="getEstimateData()"
                  data-toggle="tab"
                  href="#receipts"
                  role="tab"
                  aria-controls="people"
                  aria-selected="false"
                >Estimate</a>
              </li>
            </ul>
          </div>
          <!-- nav-wrapper -->
        </div>
        <!-- profile-body-header -->
        <div class="tab-content p-3">
          <div id="overview" class="tab-pane active show">
            <div class="stat-profile">
              <div class="stat-profile-body">
                <div class="row row-xs px-2">
                  <div class="col pd-r-7-5 pd-l-7-5">
                    <div class="card card-body pd-10 pd-md-15 bd-0 shadow-none bg-primary-light">
                      <h3
                        class="tx-normal tx-sans tx-spacing--4 tx-primary mg-b-5"
                      >{{estimate_amount}}</h3>
                      <p class="font-s tx-color-02 mg-b-0">Estimate Amount</p>
                    </div>
                  </div>
                  <div class="col pd-r-7-5 pd-l-7-5">
                    <div class="card card-body pd-10 pd-md-15 bd-0 shadow-none bg-teal-light">
                      <h3 class="mg-b-5 tx-sans tx-spacing--2 tx-normal tx-teal">{{followup_count}}</h3>
                      <p class="font-s tx-color-03 mg-b-0">Follow Up Count</p>
                    </div>
                  </div>
                  <div class="col pd-r-7-5 pd-l-7-5">
                    <div class="card card-body pd-10 pd-md-15 bd-0 shadow-none bg-pink-light">
                      <h3 class="mg-b-5 tx-sans tx-spacing--2 tx-normal tx-pink">{{lead_days}}</h3>
                      <p class="font-s tx-color-03 mg-b-0">Lead Age Days</p>
                    </div>
                  </div>
                </div>
                <!-- row -->
              </div>
              <!-- stat-profile-body -->
            </div>
            <!-- stat-profile -->

            <label class="content-label content-label-lg mt-3 tx-color-01">Description</label>
            <p class="tx-color-03 m-0">{{ lead.description }}</p>

            <hr class="mt-3" />
            <div class="row">
              <div class="col-md-7">
                <label class="content-label content-label-lg pt-1 tx-color-01">Contact Persons</label>
              </div>
              <div class="col-md-5 tx-right pb-2">
                <button
                  class="btn btn-xs btn-primary"
                  v-if="checkSinglePermission('create_contact_detail')"
                  @click="
                    $store.dispatch('modalWithMode', {
                      id: 'newLeadContact',
                      mode: 'create',
                    })
                  "
                >Add Contact Person</button>
              </div>
            </div>
            <div
              class="row pd-r-7-5 pd-l-7-5"
              v-if="contact_persons && checkSinglePermission('view_contact_detail')"
            >
              <div
                class="col-md-4 pd-r-7-5 pd-l-7-5"
                v-for="(contact, index) in contact_persons"
                :key="index"
              >
                <div class="card p-3 contact-card">
                  <ul class="list-unstyled profile-info-list mb-2">
                    <li>
                      <span class="tx-color-03">Name: {{ contact.name }}</span>
                    </li>
                    <li>
                      <span class="tx-color-03">Job Title: {{ contact.designation }}</span>
                    </li>
                    <li>
                      <span class="tx-color-03">Phone: {{ contact.phone_no }}</span>
                    </li>
                    <li>
                      <span class="tx-color-03">Email: {{ contact.email }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="bills" role="tabpanel" aria-labelledby="contact-tab5">
            <div class="row mg-0">
              <div class="col-sm-5">
                <div class="content-header pd-l-0">
                  <div>
                    <h5 class="content-title font-xxl">Manage Follow Up</h5>
                  </div>
                </div>
                <!-- content-header -->
              </div>
              <!-- col -->
              <div class="col-sm-0 tx-right col-lg-7">
                <button
                  type="button"
                  v-if="checkSinglePermission('create_follow_up')"
                  class="btn btn-sm btn-primary mg-r-0 mg-b-0"
                  @click="
                    $store.dispatch('modalWithMode', {
                      id: 'newFollow',
                      mode: 'create',
                    })
                  "
                >New Follow Up</button>
              </div>
              <!-- col -->
            </div>
            <div class="bg-theam-secondary table-search-head">
              <div class="row">
                <div class="filter-input col-md-9 d-flex mr-0 pr-0">
                  <select v-model="followup_params.size" @change="filterFollowUp">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                  </select>
                  <div class="ml-2">
                    <label for class="filter-lavel mb-0 mr-1">From</label>
                    <input
                      type="date"
                      placeholder="From"
                      class="filter-input"
                      v-model="followup_params.from"
                      @change="filterFollowUp"
                    />
                    <label for class="filter-lavel mb-0 ml-1">To</label>
                    <input
                      type="date"
                      placeholder="To"
                      class="ml-1 filter-input"
                      v-model="followup_params.to"
                      @change="filterFollowUp"
                    />
                  </div>
                </div>
                <!-- <div class="col-md-3 ml-0 pl-0">
                  <input
                    type="text"
                    class="float-right"
                    placeholder="Search..."
                  />
                </div>-->
              </div>
            </div>
            <table class="table table2 table-hover" v-if="followUpDatas">
              <thead>
                <tr>
                  <th class="wd-5px table-start-item">S.N.</th>
                  <th class="wd-10p">Date</th>
                  <th class="wd-55p">Follow Up Report</th>
                  <th class="wd-10p">Next Follow</th>
                  <th class="wd-10p">To Do</th>
                  <th
                    class="wd-10p text-right table-end-item"
                    v-if="checkIfAnyPermission(['edit_follow_up','delete_follow_up'])"
                  >Action</th>
                </tr>
              </thead>

              <tbody v-if="!followup_params.loading && followUpDatas.length > 0">
                <tr v-for="(followData, index) in followUpDatas" :key="index">
                  <td class="table-start-item">{{ followup_params.pageSerialNo+index }}.</td>
                  <td>{{ followData.follow_date }}</td>
                  <td>
                    <p class="mb-1">{{ followData.report }}</p>
                    <span>
                      <strong>Followed By:</strong>
                      <a href="javascript:;">{{followData.user_details.name}}</a>
                    </span>
                  </td>
                  <td>{{followData.next_follow_date}}</td>
                  <td>
                    <span :class="'badge badge-pill badge-'+ followData.lead_todo.color">
                      {{
                      followData.lead_todo.title
                      }}
                    </span>
                  </td>
                  <td
                    class="text-right table-end-item"
                    v-if="checkIfAnyPermission(['edit_follow_up','delete_follow_up'])"
                  >
                    <a
                      href="javascript:;"
                      v-if="checkSinglePermission('edit_follow_up')"
                      class="mr-3"
                      @click="editFollow('editFollow', followData.id)"
                    >
                      <i class="fa fa-edit"></i>
                    </a>
                    <a
                      href="javascript:;"
                      v-if="checkSinglePermission('delete_follow_up')"
                      @click="dropFollow(followData.id)"
                    >
                      <i class="fa fa-trash"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
              <tbody v-else-if="!followup_params.loading && followUpDatas.length == 0">
                <tr>
                  <td colspan="11" style="text-align:center;">No records found.</td>
                </tr>
              </tbody>
              <tbody v-else-if="followup_params.loading">
                <tr>
                  <td colspan="11" style="text-align:center;">Loading.....</td>
                </tr>
              </tbody>
              <tbody v-else-if="followup_params.error">
                <tr>
                  <td colspan="11" class="text-center">{{followup_params.error_message}}</td>
                </tr>
              </tbody>
            </table>
            <Paginate
              :pageCount="followup_params.totalPageCount"
              :containerClass="'pagination'"
              :clickHandler="clickCallback"
              v-if="followup_params.totalPageCount > 1"
            />
          </div>
          <!---individual content-->
          <div class="tab-pane fade" id="receipts" role="tabpanel" aria-labelledby="contact-tab5">
            <div class="row mg-0">
              <div class="col-sm-5">
                <div class="content-header pd-l-0">
                  <div>
                    <h5 class="content-title font-xxl">Manage Estimate</h5>
                  </div>
                </div>
                <!-- content-header -->
              </div>
              <!-- col -->
              <div class="col-sm-0 tx-right col-lg-7">
                <button
                  type="button"
                  class="btn btn-sm btn-primary mg-r-0 mg-b-0"
                  @click="$router.push('/new/estimate')"
                >New Estimate</button>
              </div>
              <!-- col -->
            </div>
            <div class="bg-theam-secondary table-search-head">
              <div class="row">
                <div class="filter-input col-md-9 d-flex mr-0 pr-0">
                  <select v-model="estimate_params.size" @change="getEstimateData">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                  </select>
                  <div class="ml-2">
                    <label for class="filter-lavel mb-0 mr-1">From</label>
                    <input
                      type="date"
                      placeholder="From"
                      class="filter-input"
                      v-model="estimate_params.from"
                      @change="getEstimateData"
                    />
                    <label for class="filter-lavel mb-0 ml-1">To</label>
                    <input
                      type="date"
                      placeholder="To"
                      class="ml-1 filter-input"
                      v-model="estimate_params.to"
                      @change="getEstimateData"
                    />
                  </div>
                </div>
                <div class="col-md-3 ml-0 pl-0">
                  <input
                    type="text"
                    class="float-right"
                    placeholder="Search..."
                    v-model="estimate_params.searched"
                    @keyup="searchEstimate"
                  />
                </div>
              </div>
            </div>
            <table class="table table2 table-hover">
              <thead>
                <tr>
                  <th class="wd-5px table-start-item">SN.</th>
                  <th class="wd-20p">Estimate No.</th>
                  <th class="wd-25p">Date</th>
                  <th class="wd-20p">Amount</th>
                  <th class="wd-20p">Validity</th>
                  <th
                    class="wd-10px text-right table-end-item"
                    v-if="checkIfAnyPermission(['view_estimate','edit_estimate'])"
                  >Action</th>
                </tr>
              </thead>
              <tbody v-if="!estimate_params.loading && dataSets.length > 0">
                <tr v-for="(estimate,index) in dataSets" :key="index">
                  <td class="table-start-item">{{estimate_params.pageSerialNo+index}}</td>
                  <td>{{estimate.estimate_code}}</td>
                  <td>{{estimate.date}}</td>
                  <td>{{estimate.estimate_total}}</td>
                  <td><span :class="{'tx-danger':estimate.validity < today}">{{ estimate.validity }}</span></td>
                  <td
                    class="text-right table-end-item"
                    v-if="checkIfAnyPermission(['view_estimate','edit_estimate'])"
                  >
                    <a href="javascript:;" class="mr-3">
                      <i
                        class="fas fa-eye tx-success"
                        title="View"
                        v-if="checkSinglePermission('view_estimate')"
                        @click="estimateView('estimateSlip', estimate.id)"
                      ></i>
                    </a>
                    <a
                      href="javascript:;"
                      class="mr-3"
                      @click="estimateEdit(estimate.id)"
                      v-if="!estimate.canceled_by && checkSinglePermission('edit_estimate')"
                      title="Edit"
                    >
                      <i class="fa fa-edit"></i>
                    </a>
                    <!-- <a href="javascript:;">
                      <i class="fa fa-trash" title="Cancel"></i>
                    </a>-->
                  </td>
                </tr>
              </tbody>
              <tbody v-else-if="estimate_params.error">
                <tr>
                  <td colspan="11" class="text-center">{{estimate_params.error_message}}</td>
                </tr>
              </tbody>
              <tbody v-else-if="!estimate_params.loading && dataSets.length == 0">
                <tr>
                  <td colspan="11" style="text-align:center;">No records found.</td>
                </tr>
              </tbody>
              <tbody v-else-if="estimate_params.loading">
                <tr>
                  <td colspan="11" style="text-align:center;">Loading.....</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!--individual-content-->
        </div>
        <!-- tab-content -->
      </div>
      <!-- profile-body -->
    </div>
    <!-- content-body -->

    <ContactDetail />
    <EstimateSlip />
    <NewFollow @follow-create="filterFollowUp()" />
    <EditFollow @follow-edit="getFollowupData()" />
    <EditLead @profile-edit="getData()" />
  </div>
  <!-- content -->
</template>
<script>
import ContactDetail from "../Lead/NewContact";
import NewFollow from "../Lead/NewFollow";
import Paginate from "vuejs-paginate";
import EditFollow from "../Lead/EditFollow";
import EditLead from "../Lead/EditLead";
import Services from "./Services/Services";
import EstimateSlip from "../Estimate/Components/EstimateSlip";
import EstimateServices from "../Estimate/Services/Service";
import { mapGetters } from "vuex";
import _ from "lodash";
export default {
  data() {
    return {
      progrees_percent: "",
      progress_color: "",
      id: "",
      estimate_amount: "",
      followup_count: "",
      lead_days: "",
      followup_params: {
        id: this.$route.params.id,
        loading: true,
        error: false,
        size: 10,
        from: "",
        to: "",
        offset: 0,
        error_message: "",
        totalPageCount: 0,
        pageSerialNo: 1,
      },
      estimate_params: {
        id: this.$route.params.id,
        loading: false,
        error: false,
        error_message: "",
        size: 10,
        from: "",
        to: "",
        offset: 0,
        searched: "",
        totalPageCount: 0,
        pageSerialNo: 1,
      },
    };
  },
  components: {
    ContactDetail,
    NewFollow,
    EditLead,
    EditFollow,
    Paginate,
    EstimateSlip,
  },
  computed: {
    ...mapGetters([
      "dataLists",
      "dataLists1",
      "dataLists2",
      "dataLists4",
      "dataLists6",
      "dataId",
      "today",
      "modalId",
      "modalMode",
    ]),
    ...mapGetters("leads", [
      "contact_persons",
      "lead",
      "followUpDatas",
      "estimateDatas",
    ]),
    ...mapGetters("estimate", ["dataSets", "slipSettings"]),
  },
  methods: {
    clickCallback: function (page) {
      if (page == 1) {
        this.followup_params.pageSerialNo = 1;
      } else {
        this.followup_params.pageSerialNo =
          (page - 1) * this.followup_params.size + 1;
      }
      this.followup_params.offset = (page - 1) * this.followup_params.size;
      this.filterFollowUp();
    },
    setNotification(msg) {
      this.$store.dispatch("enableMessage");
      this.$store.commit("setEventMessage", msg);
    },
    getData() {
      this.id = this.$route.params.id;
      Services.getDataForLeadProfile(this.id)
        .then((res) => {
          this.$store.commit(
            "leads/setContactPersons",
            res.data.data.contactDetails
          );
          let value = res.data.data.lead;
          this.$store.commit("leads/setLead", value);
          this.estimate_amount = res.data.data.estimates;
          this.lead_days = res.data.data.lead_days;
          this.followup_count = res.data.data.follow_up_count;
          this.progrees_percent = value.lead_priority.percentage;
          if (this.progrees_percent <= 25) {
            this.progress_color = "danger";
          } else if (this.progrees_percent <= 50) {
            this.progress_color = "info";
          } else if (this.progrees_percent <= 75) {
            this.progress_color = "warning";
          } else if (this.progrees_percent <= 100) {
            this.progress_color = "success";
          } else {
            this.progress_color = "primary";
          }
          // this.getFollowupData();
          // this.getEstimateData();
        })
        .catch((err) => {
          console.log(err);
        });
      // this.$store.commit(
      //   "getData6",
      //   `api/lead-follow/show/${this.id}`
      // );
    },
    getFollowupData() {
      this.followup_params.loading = true;
      Services.getFollowUpFilterData(this.followup_params)
        .then((res) => {
          this.$store.commit("leads/setFollowUpDatas", res.data.data.data);
          this.followup_params.totalPageCount = res.data.data.total_no_of_pages;
        })
        .catch((err) => {
          this.followup_params.error = true;
          if (
            !Object.prototype.hasOwnProperty.call(
              err,
              "response.data.error_message"
            )
          ) {
            if (err.response.data.error_message == "Access Denied.") {
              this.followup_params.error_message = "Access Denied !!!";
            } else {
              this.followup_params.error_message =
                "Error fetching data from the server.";
            }
          }
        })
        .finally(() => {
          this.followup_params.loading = false;
        });
    },
    searchEstimate: _.debounce(function () {
      this.estimate_params.page = 1;
      this.estimate_params.pageSerialNo = 1;
      this.estimate_params.offset = 0;
      this.getEstimateData();
    }, 500),
    getEstimateData() {
      this.estimate_params.loading = true;
      Services.getEstimateDataByLead(this.estimate_params)
        .then((res) => {
          this.$store.commit("estimate/setDataSets", res.data.data.data);
        })
        .catch((err) => {
          this.estimate_params.error = true;
          if (
            !Object.prototype.hasOwnProperty.call(
              err,
              "response.data.error_message"
            )
          ) {
            if (err.response.data.error_message == "Access Denied.") {
              this.estimate_params.error_message = "Access Denied !!!";
            } else {
              this.estimate_params.error_message =
                "Error fetching data from the server.";
            }
          }
        })
        .finally(() => {
          this.estimate_params.loading = false;
        });
      EstimateServices.getSlipPredefinedData()
        .then((response) => {
          // this.$store.commit('setDataLists9', response.data.data);
          this.$store.commit("estimate/setSlipSettings", response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    estimateView(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "read" });
    },
    estimateEdit(id) {
      this.$router.push({ name: "edit-estimate", params: { id: id } });
    },
    edit(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "edit" });
    },
    editFollow(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "editFollow" });
    },
    filterFollowUp() {
      this.followup_params.loading = true;
      Services.getFollowUpFilterData(this.followup_params)
        .then((res) => {
          this.$store.commit("leads/setFollowUpDatas", res.data.data.data);
          this.followup_params.totalPageCount = res.data.data.total_no_of_pages;
          this.followup_params.loading = false;
        })
        .catch((err) => {
          this.followup_params.loading = false;
          this.followup_params.error = true;
          if (
            !Object.prototype.hasOwnProperty.call(
              err,
              "response.data.error_message"
            )
          ) {
            if (err.response.data.error_message == "Access Denied.") {
              this.followup_params.error_message = "Access Denied !!!";
            } else {
              this.followup_params.error_message =
                "Error fetching data from the server.";
            }
          }
        });
    },
    dropFollow(id) {
      this.$swal({
        title: "Do you really want to delete Follow Up ?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Delete",
        cancelButtonText: "No",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          // delete lead
          Services.deleteFollowUpData(id)
            .then((response) => {
              if (response.status == 200) {
                let success = response.data.success_message;
                this.setNotification(success);
              }
              this.filterFollowUp();
            })
            .catch((error) => {
              if (error.response.status == 500) {
                let err = error.response.data.error_message;
                this.setNotification(err);
              }
            });
        },
      });
    },
  },
  beforeMount() {
    this.id = this.$route.params.id;
    this.getData();
  },
  watch: {
    eventMessage(value) {
      if (value.indexOf("Follow Report Deleted Successfully.") >= 0) {
        this.$store.commit("setRemoveList", this.dataId);
      }
    },
    destroyed() {
      this.$store.dispatch("destroyEvent");
    },
  },
};
</script>
<style scoped>
.contact-card {
  margin-bottom: 10px;
}
</style>
